const moment = require('moment')

const url = 'https://fliphtml5download.com/'
const shortTitle = 'FlipHTML5Download'
const description =
  "FlipHTML5Download.com is a free online tool for converting any document from FlipHTML5 to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = ['https://fliphtml5.com/wsdh/nmwb']

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: false,
  documentRoot: '/:author/:document',
  analyticsDocumentRoot: '/:author/:document',
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/Gm8Y5/stub"></script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/Gm8Y5/cmp" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { author, document } = params
    return `https://fliphtml5.com/${author}/${document}`
  },
  service: {
    name: 'FlipHTML5',
    url: 'https://www.fliphtml5.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    google: 'UA-132816870-1',
    viewId: '188332356',
    yandex: '25e3d770fa1e0a3d',
    gtm: 'GTM-T7HGG87',
  },
  contactEmail: 'contact@fliphtml5download.com',
  meta: {
    description,
    keywords:
      'fliphtml5, pdf, download,free,fliphtml5 to pdf,fliphtml5 download,fliphtml5 pdf download,pdf download,fliphtml5 downloader',
    author: 'Ludovic LEFEVRE',
    title: 'FlipHTML5 To PDF Download Tool',
    applicationName: 'FlipHTML5Download.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'FlipHTML5 Download',
    shortTitle: 'FlipHTML5 Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Ffliphtml5download.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Ffliphtml5download.com&text=FlipHTML5Download.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//fliphtml5download.com&title=FlipHTML5%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'fliphtml5',
  },
  screenshots: ['/img/fliphtml5/get-fliphtml5-document-url-01.jpg'],
}

export default config
