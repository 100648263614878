const moment = require('moment')
const url = 'https://calameodownload.com/'
const shortTitle = 'CalameoDownload'
const description =
  "CalameoDownload.com is a free online tool for converting any document from Calameo to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = ['http://en.calameo.com/read/0002739392322aca0ad22']

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: true,
  documentRoot: '/:directory/:document',
  analyticsDocumentRoot: '/:directory/:document',
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/Zf852/stub"></script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/Zf852/cmp" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params, query) => {
    const { document } = params
    return `https://www.calameo.com/read/${document}${query}`
  },
  service: {
    name: 'Calameo',
    url: 'https://www.calameo.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    google: 'UA-85846784-1',
    viewId: '131724880',
    yandex: '0d3fcbe7215d6248',
    gtm: 'GTM-54PP3D7',
  },
  contactEmail: 'contact@calameodownload.com',
  meta: {
    description,
    keywords:
      'calameo, pdf, download,free,calameo to pdf,calameo download,calameo pdf download,pdf download,calameo downloader',
    author: 'Ludovic LEFEVRE',
    title: 'Calameo To PDF Download Tool',
    applicationName: 'CalameoDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'Calameo Download',
    shortTitle: 'Calameo Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fcalameodownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fcalameodownload.com&text=CalameoDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//calameodownload.com&title=Calameo%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'calameo',
  },
  screenshots: ['/img/calameo/get-calameo-document-url-01.jpg', '/img/calameo/get-calameo-document-url-02.jpg'],
}

export default config
