/* global $ */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _size from 'lodash/size'

class Gallery extends Component {
  componentDidMount() {
    if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
    $('#lightgallery').lightGallery({
      thumbnail: true,
      selector: '.portfolio-link',
    })
  }

  componentDidUpdate() {
    if ($('#lightgallery').data('lightGallery')) $('#lightgallery').data('lightGallery').destroy(true)
    $('#lightgallery').lightGallery({
      thumbnail: true,
      selector: '.portfolio-link',
    })
  }

  onViewClick = (e) => {
    e.preventDefault()
    $(e.target).closest('.card').find('.portfolio-link').click()
  }

  GalleryAd = (props) => {
    const { index } = props
    return null
    if (index === 0 || index % 10 !== 0) return null

    return (
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
        <Card className="card ad">
          <ins
            className="adsbygoogle activate gallery-ad"
            style={{ display: 'inline-block' }}
            data-ad-client="ca-pub-1611108288002181"
            data-ad-slot="7343710550"
            data-ad-format="auto"
          />
        </Card>
      </div>
    )
  }

  Gallery = (props) => {
    const { pictures } = props
    const { onViewClick, GalleryAd } = this
    const count = _size(pictures.pages)
    if (count <= 0) {
      return null
    }
    let index = -1
    return pictures.pages.map((picture) => {
      index++
      return (
        <Fragment key={index}>
          <GalleryAd index={index} />
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
            <Card className="card">
              <a href={picture} className="portfolio-link">
                <img className="card-img-top" src={picture} alt="" />
              </a>
              <div className="card-body">
                <div className="card-text">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col">
                        {index + 1}/{count}
                      </div>
                      <div className="col">
                        <a href="" onClick={onViewClick}>
                          <i className="fas fa-eye" />
                        </a>
                      </div>
                      <div className="col">
                        <a href={picture} target="_blank" download>
                          <i className="fas fa-download" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Fragment>
      )
    })
  }

  render() {
    const { pictures } = this.props
    const { Gallery } = this
    return (
      <div id="lightgallery" className="container">
        <div className="row">
          <Gallery pictures={pictures} />
        </div>
      </div>
    )
  }
}

const Card = styled.div`
  margin: 10px;
  &.ad {
    content: '';
    display: block;
    padding-bottom: 100%;
    overflow: hidden;
  }
`

Gallery.propTypes = {
  pictures: PropTypes.object,
}

export default Gallery
