import _partial from 'lodash/partial'
import api from '../utils/api/api'

const log = (level, message) => {
  console[level](message)
  api.log(level, message)
}

export default {
  error: _partial(log, 'error'),
}
