import moment from 'moment'

const url = 'https://yumpudownload.com/'
const shortTitle = 'YumpuDownload'
const description =
  "YumpuDownload.com is a free online tool for converting any document from Yumpu to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = [
  'https://www.yumpu.com/en/document/view/62639082/mini-guide-australia-2019',
  'https://www.yumpu.com/en/document/view/59433820/climber-september-october-2017',
]

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: true,
  documentRoot: [
    '/:language/document/view/:author/:document',
    '/:language/embed/view/:document',
    '/:language/document/read/:author/:document',
    '/:language/embed/read/:document',
  ],
  analyticsDocumentRoot: [
    '/:language/document/view/:author/:document',
    '/:language/embed/view/:document',
    '/:language/document/read/:author/:document',
    '/:language/embed/read/:document',
  ],
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: false,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/VTqtD/stub"></script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/VTqtD/cmp" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { author, document } = params
    if (!author) {
      return `https://www.yumpu.com/en/embed/view/${document}`
    }
    return `https://www.yumpu.com/en/document/view/${author}/${document}`
  },
  service: {
    name: 'Yumpu',
    url: 'https://www.yumpu.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    google: 'UA-115089163-1',
    viewId: '170841779',
    yandex: 'c49e16ac3ca9c505',
    gtm: 'GTM-52WZGS6',
  },
  contactEmail: 'contact@yumpudownload.com',
  meta: {
    description,
    keywords: 'yumpu, pdf, download,free,yumpu to pdf,yumpu download,yumpu pdf download,pdf download,yumpu downloader',
    author: 'Ludovic LEFEVRE',
    title: 'Yumpu To PDF Download Tool',
    applicationName: 'YumpuDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'Yumpu Download',
    shortTitle: 'Yumpu Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fyumpudownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fyumpudownload.com&text=YumpuDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//yumpudownload.com&title=Yumpu%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'yumpu',
  },
  screenshots: ['/img/yumpu/get-yumpu-document-url-01.jpg'],
}

export default config
