const moment = require('moment')

const url = 'https://flipgorilladownload.com/'
const shortTitle = 'FlipGorillaDownload'
const description =
  "FlipGorillaDownload.com is a free online tool for converting any document from FlipGorilla to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = ['http://www.flipgorilla.com/p/23023990364716218/show']

const config = {
  enabled: false,
  documentRoot: '/p/:document/show',
  analyticsDocumentRoot: '/p/:document/show',
  apiRootUrl: 'https://backend.img2pdf.net',
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/sdL82/stub" referrerpolicy="origin" charset="utf-8"></script>
      <script>
      window.Sddan = {
          "info": {
              "hd_m" : "",
              "hd_s256" : "",
              "uf_postal_code" : "",
              "uf_bday" : "",
              "uf_gender" : "",
          }
      };
      </script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/sdL82/cmp" referrerpolicy="origin" charset="utf-8" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { document } = params
    return `https://flipgorilla.com/p/${document}/show`
  },
  service: {
    name: 'FlipGorilla',
    url: 'https://www.flipgorilla.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    google: 'UA-126152708-1',
    viewId: '182059995',
    yandex: '13a333cda637a30b',
    gtm: 'GTM-MKHTSXN',
  },
  contactEmail: 'contact@flipgorilladownload.com',
  meta: {
    description,
    keywords:
      'flipgorilla, pdf,download,free,flipgorilla to pdf,flipgorilla download,flipgorilla pdf download,pdf download,flipgorilla downloader',
    author: 'Ludovic LEFEVRE',
    title: 'FlipGorilla To PDF Download Tool',
    applicationName: 'FlipGorillaDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'FlipGorilla Download',
    shortTitle: 'FlipGorilla Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fflipgorilladownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fflipgorilladownload.com&text=FlipGorillaDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//flipgorilladownload.com&title=FlipGorilla%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'flipgorilla',
  },
  screenshots: ['/img/flipgorilla/get-flipgorilla-document-url-01.jpg'],
}

export default config
