import shortid from 'shortid'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import config from '../../config'

const apiRootUrl = config.apiRootUrl

const scope = process.env.REACT_APP_SCOPE
const inDebugMode = process.env.REACT_APP_API_DEBUG === 'true'
const requestId = process.env.REACT_APP_REQUEST_ID

const defaultHeaders = {
  Scope: scope,
  'Content-Type': 'application/json',
  'Custom-Request-Id': requestId,
}

const handleResponse = (response) => {
  if (!response.ok) {
    const errorTextPromise = response.text().catch((e) => 'NoResponseText')
    return errorTextPromise.then((text) => {
      console.error('API Error', text, response)
      throw new Error('API Error: ' + text)
    })
  }
  if (inDebugMode) {
    console.log(response)
  }
  return response.json()
}

const logDebugMessage = (label, url, postedData) => {
  return (responseData) => {
    if (inDebugMode) {
      console.log(label, url, 'postedData', postedData, 'responseData', responseData)
    }
    return responseData
  }
}

const delay = (response) => {
  if (process.env.NODE_ENV !== 'development') {
    return Promise.resolve(response)
  }
  // We delay all requests in dev so that it's easier to debug loaders in app...
  return new Promise((resolve) => {
    setTimeout(() => resolve(response), 500)
  })
}

const pollStatus = (fn, timeout, interval, progressFn) => {
  const endTime = Number(new Date()) + (timeout || 2000)
  interval = interval || 100

  return new Promise(function (resolve, reject) {
    const p = () => {
      fn().then((data) => {
        if (data.status === 'succeeded') {
          return resolve(data)
        }
        if (data.status === 'failed') {
          return reject(new Error('failed'))
        }
        if (Number(new Date()) < endTime) {
          const progress = _get(data, 'progress', false)
          if (progress && _isFunction(progressFn)) {
            progressFn(progress)
          }
          return setTimeout(p, interval)
        }
        reject(new Error('timed out'))
      })
    }
    p()
  })
}

export default {
  getImages: (url) => {
    const getImagesUrl = apiRootUrl + '/images'
    const data = {
      url,
    }
    return fetch(getImagesUrl, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify(data),
    })
      .then(delay)
      .then(handleResponse)
      .then(logDebugMessage('getImages', url, null))
  },
  generatePdf: (url) => {
    const getImagesUrl = apiRootUrl + '/download-pdf'
    const data = {
      url,
    }
    return fetch(getImagesUrl, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify(data),
    })
      .then(delay)
      .then(handleResponse)
      .then(logDebugMessage('generatePdf', url, null))
  },
  checkStatus: (jobId, progressFn) => {
    return pollStatus(
      () => {
        const guid = shortid.generate()
        const checkStatusUrl = `${apiRootUrl}/job/${jobId}?cache=${guid}`
        return fetch(checkStatusUrl, {
          method: 'get',
          headers: defaultHeaders,
        })
          .then(delay)
          .then(handleResponse)
          .then(logDebugMessage('checkStatus', checkStatusUrl, null))
      },
      3 * 60 * 1000,
      500,
      progressFn
    )
  },
  getHistory: () => {
    const getHistoryUrl = apiRootUrl + '/history'
    return fetch(getHistoryUrl, {
      method: 'GET',
      headers: defaultHeaders,
    })
      .then(delay)
      .then(handleResponse)
      .then(logDebugMessage('history', getHistoryUrl, null))
  },
  log: (level, message) => {
    const url = apiRootUrl + `/log/${level}`
    return fetch(url, {
      method: 'POST',
      headers: defaultHeaders,
      body: JSON.stringify({
        message,
      }),
    })
      .then(delay)
      .then(logDebugMessage('log', url, null))
  },
}
