import moment from 'moment'
import urlJoin from 'url-join'
import tldjs from 'tldjs'
import _isEmpty from 'lodash/isEmpty'

const url = 'https://tiendeodownload.com/'
const shortTitle = 'TiendeoDownload'
const description =
  "TiendeoDownload.com is a free online tool for converting any document from Tiendeo to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = [
  'https://www.tiendeo.fr/Catalogues/bordeaux/492121',
  'https://www.tiendeo.us/Catalogs/new-york-city-NY/1235933',
]

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: true,
  documentRoot: ['/:domain/:catalog/:document', '/:domain/:catalog/:city/:document'],
  analyticsDocumentRoot: ['/:domain/:catalog/:document', '/:domain/:catalog/:city/:document'],
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/HsEaE/stub"></script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/HsEaE/cmp" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const tld = tldjs.parse(url)
    const { publicSuffix } = tld
    const { pathname } = parsedUrl
    return urlJoin(publicSuffix, pathname)
  },
  getDocumentUrlFromParams: (params) => {
    const { domain, catalog, city, document } = params
    const rootUrl = `https://www.tiendeo.${domain}`
    if (!_isEmpty(city)) {
      return urlJoin(rootUrl, catalog, city, document)
    }
    return urlJoin(rootUrl, catalog, document)
  },
  service: {
    name: 'Tiendeo',
    url: 'https://www.tiendeo.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    google: 'UA-147654505-1',
    viewId: '201779678',
    yandex: '6d15c21b884afac0',
    gtm: 'GTM-MTTKFJ6',
  },
  contactEmail: 'contact@tiendeodownload.com',
  meta: {
    description,
    keywords:
      'tiendeo, pdf, download,free,tiendeo to pdf,tiendeo download,tiendeo pdf download,pdf download,tiendeo downloader',
    author: 'Ludovic LEFEVRE',
    title: 'Tiendeo To PDF Download Tool',
    applicationName: 'TiendeoDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'Tiendeo Download',
    shortTitle: 'Tiendeo Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Ftiendeodownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Ftiendeodownload.com&text=TiendeoDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//tiendeodownload.com&title=Tiendeo%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'tiendeo',
  },
  screenshots: ['/img/tiendeo/get-tiendeo-document-url-01.jpg'],
}

export default config
