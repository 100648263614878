import React, { Component } from 'react'
import styled from 'styled-components'
import log from '../utils/log'

class Error extends Component {
  componentDidMount() {
    const { pathname, search, hash } = this.props.location
    log.error(`Unhandled route: ${pathname}${search}${hash}`)
  }

  render() {
    return (
      <Container class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <h2>Error</h2>
            <p>Sorry the document can't be processed.</p>
            <a href="/" className="btn btn-light js-scroll-trigger">
              Return to home page
            </a>
          </div>
        </div>
      </Container>
    )
  }
}

const Container = styled.div`
  margin-top: 50px;
`

export default Error
