import _isEmpty from 'lodash/isEmpty'
import issuu from './config/issuu'
import fliphtml5 from './config/fliphtml5'
import flipgorilla from './config/flipgorilla'
import anyflip from './config/anyflip'
import calameo from './config/calameo'
import yumpu from './config/yumpu'
import flipsnack from './config/flipsnack'
import joomag from './config/joomag'
import tiendeo from './config/tiendeo'

const scope = process.env.REACT_APP_SCOPE
if (_isEmpty(scope)) throw new Error('REACT_APP_SCOPE is undefined.')

let config = {}
switch (scope) {
  case 'issuu':
    config = issuu
    break
  case 'fliphtml5':
    config = fliphtml5
    break
  case 'flipgorilla':
    config = flipgorilla
    break
  case 'anyflip':
    config = anyflip
    break
  case 'calameo':
    config = calameo
    break
  case 'yumpu':
    config = yumpu
    break
  case 'flipsnack':
    config = flipsnack
    break
  case 'joomag':
    config = joomag
    break
  case 'tiendeo':
    config = tiendeo
    break
  default:
    throw new Error(`Undefined scope: ${scope}`)
}
config.version = process.env.REACT_APP_VERSION

export default config
