import React, { Component } from 'react'
import styled from 'styled-components'
import config from '../config'

class PrivacyPolicy extends Component {
  render() {
    return (
      <div>
        <PageSection id="pages" className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col">
                <p>{config.meta.applicationName} Privacy Policy</p>

                <p>
                  This privacy policy has been compiled to better serve those who are concerned with how their
                  ‘Personally Identifiable Information’ (PII) is being used online. PII, as described in US privacy law
                  and information security, is information that can be used on its own or with other information to
                  identify, contact, or locate a single person, or to identify an individual in context. Please read our
                  privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise
                  handle your Personally Identifiable Information in accordance with our website.
                </p>

                <p>What personal information do we collect from the people that visit our blog, website or app?</p>

                <p>
                  We do not collect information from visitors of our site. or other details to help you with your
                  experience.
                </p>

                <p>Do we use ‘cookies’?</p>

                <p>
                  Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard
                  drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to
                  recognize your browser and capture and remember certain information. For instance, we use cookies to
                  help us remember and process the items in your shopping cart. They are also used to help us understand
                  your preferences based on previous or current site activity, which enables us to provide you with
                  improved services. We also use cookies to help us compile aggregate data about site traffic and site
                  interaction so that we can offer better site experiences and tools in the future.
                </p>

                <p>We use cookies to:</p>
                <p>
                  • Compile aggregate data about site traffic and site interactions in order to offer better site
                  experiences and tools in the future. We may also use trusted third-party services that track this
                  information on our behalf.
                </p>

                <p>
                  You can choose to have your computer warn you each time a cookie is being sent, or you can choose to
                  turn off all cookies. You do this through your browser settings. Since browser is a little different,
                  look at your browser’s Help Menu to learn the correct way to modify your cookies.
                </p>

                <p>
                  If you turn cookies off, some features will be disabled. It won’t affect the user’s experience that
                  make your site experience more efficient and may not function properly.
                </p>

                <p>Third-party disclosure</p>

                <p>
                  We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable
                  Information.
                </p>

                <p>Third-party links</p>

                <p>We do not include or offer third-party products or services on our website.</p>

                <p>Google</p>

                <p>
                  Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in
                  place to provide a positive experience for users.
                  https://support.google.com/adwordspolicy/answer/1316548?hl=en
                </p>

                <p>We use Google AdSense Advertising on our website.</p>

                <p>
                  Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the DART
                  cookie enables it to serve ads to our users based on previous visits to our site and other sites on
                  the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content
                  Network privacy policy.
                </p>

                <p>We have implemented the following:</p>
                <p>• Remarketing with Google AdSense</p>
                <p>• DoubleClick Platform Integration</p>

                <p>
                  We, along with third-party vendors such as Google use first-party cookies (such as the Google
                  Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party
                  identifiers together to compile data regarding user interactions with ad impressions and other ad
                  service functions as they relate to our website.
                </p>

                <p>Opting out:</p>
                <p>
                  Users can set preferences for how Google advertises to you using the Google Ad Settings page.
                  Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using
                  the Google Analytics Opt Out Browser add on.
                </p>

                <p>California Online Privacy Protection Act</p>

                <p>
                  CalOPPA is the first state law in the nation to require commercial websites and online services to
                  post a privacy policy. The law’s reach stretches well beyond California to require any person or
                  company in the United States (and conceivably the world) that operates websites collecting Personally
                  Identifiable Information from California consumers to post a conspicuous privacy policy on its website
                  stating exactly the information being collected and those individuals or companies with whom it is
                  being shared. - See more at:{' '}
                  <a
                    href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
                  </a>
                </p>

                <p>According to CalOPPA, we agree to the following:</p>
                <p>Users can visit our site anonymously.</p>
                <p>
                  Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the
                  first significant page after entering our website.
                </p>
                <p>
                  Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified
                  above.
                </p>

                <p>How does our site handle Do Not Track signals?</p>
                <p>
                  We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track
                  (DNT) browser mechanism is in place.
                </p>

                <p>Does our site allow third-party behavioral tracking?</p>
                <p>It’s also important to note that we do not allow third-party behavioral tracking</p>

                <p>COPPA (Children Online Privacy Protection Act)</p>

                <p>
                  When it comes to the collection of personal information from children under the age of 13 years old,
                  the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade
                  Commission, United States’ consumer protection agency, enforces the COPPA Rule, which spells out what
                  operators of websites and online services must do to protect children’s privacy and safety online.
                </p>

                <p>We do not specifically market to children under the age of 13 years old.</p>

                <p>Fair Information Practices</p>

                <p>
                  The Fair Information Practices Principles form the backbone of privacy law in the United States and
                  the concepts they include have played a significant role in the development of data protection laws
                  around the globe. Understanding the Fair Information Practice Principles and how they should be
                  implemented is critical to comply with the various privacy laws that protect personal information.
                </p>

                <p>
                  In order to be in line with Fair Information Practices we will take the following responsive action,
                  should a data breach occur:
                </p>
                <p>We will notify the users via in-site notification</p>
                <p>• Within 7 business days</p>

                <p>
                  We also agree to the Individual Redress Principle which requires that individuals have the right to
                  legally pursue enforceable rights against data collectors and processors who fail to adhere to the
                  law. This principle requires not only that individuals have enforceable rights against data users, but
                  also that individuals have recourse to courts or government agencies to investigate and/or prosecute
                  non-compliance by data processors.
                </p>

                <p>
                  {config.meta.applicationName} obtains your consent in accordance with the policies and technical
                  specifications of the IAB Europe Transparency & Consent Framework. It uses the Consent Management
                  Platform n°92.
                  <br />
                  <br />
                  You can change your choices at any time by{' '}
                  <a href="javascript:Sddan.cmp.displayUI()">clicking here</a>.
                </p>

                <p>Contacting Us</p>

                <p>
                  If there are any questions regarding this privacy policy, you may contact us using the information
                  below.
                </p>

                <p>{config.contactEmail}</p>

                <p>Last Edited on 2022-07-05</p>
              </div>
            </div>
          </div>
        </PageSection>
      </div>
    )
  }
}

const PageSection = styled.section`
  padding-top: 50px !important;
  padding-bottom: 50px !important;
`

export default PrivacyPolicy
