import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const LoadingButton = ({ className, label, loadingLabel, isLoading, ...props }) => {
  const Label = () => {
    const processing = loadingLabel || 'Processing...'
    if (isLoading) {
      return { __html: `${processing} <i class="fas fa-spinner fa-spin"/>` }
    }
    return { __html: label }
  }

  return (
    <button
      className={classnames('btn', 'btn-primary', className)}
      disabled={isLoading}
      {...props}
      dangerouslySetInnerHTML={Label()}
    />
  )
}

LoadingButton.propTypes = {
  pictures: PropTypes.object,
}

export default LoadingButton
