import moment from 'moment'

const url = 'https://anyflipdownload.com/'
const shortTitle = 'AnyFlipDownload'
const description =
  "AnyFlipDownload.com is a free online tool for converting any document from AnyFlip to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = ['http://anyflip.com/xkaeq/zmmj']

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: false,
  documentRoot: '/:author/:document/:optional?',
  analyticsDocumentRoot: '/:author/:document(/:optional)',
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/MVium/stub"></script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/MVium/cmp" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    console.log('coucou', parsedUrl)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { author, document } = params
    return `https://anyflip.com/${author}/${document}`
  },
  service: {
    name: 'AnyFlip',
    url: 'https://www.anyflip.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    google: 'UA-123000520-1',
    viewId: '179161914',
    yandex: '37f58cc4eb7b49fa',
    gtm: 'GTM-THGTPFD',
  },
  contactEmail: 'contact@anyflipdownload.com',
  meta: {
    description,
    keywords:
      'anyflip, pdf,download,free,anyflip to pdf,anyflip download,anyflip pdf download,pdf download,anyflip downloader',
    author: 'Ludovic LEFEVRE',
    title: 'AnyFlip To PDF Download Tool',
    applicationName: 'AnyFlipDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'AnyFlip Download',
    shortTitle: 'AnyFlip Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fanyflipdownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fanyflipdownload.com&text=AnyFlipDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//anyflipdownload.com&title=AnyFlip%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'anyflip',
  },
  screenshots: ['/img/anyflip/get-anyflip-document-url-01.jpg'],
}

export default config
