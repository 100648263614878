import React, { Component } from 'react'
import styled from 'styled-components'
import urlJoin from 'url-join'
import _isEmpty from 'lodash/isEmpty'
import _size from 'lodash/size'
import _first from 'lodash/first'
import _sample from 'lodash/sample'
import api from '../utils/api/api'
import LoadingButton from '../components/LoadingButton.js'
import LoadingSpinner from '../components/LoadingSpinner'
import config from '../config'

class Home extends Component {
  state = {
    url: '',
    exampleUrl: _sample(config.exampleUrls),
    isLoading: false,
    isHistoryLoading: true,
    errorMessage: '',
    recentDownloads: [],
  }

  ErrorMessage = (props) => {
    const { message } = props
    if (_isEmpty(message)) {
      return null
    }
    return (
      <div className="alert alert-warning" role="alert">
        {message}
      </div>
    )
  }

  handleChange = (e) => {
    this.setState({ url: e.target.value })
  }

  handleExampleClick = (e) => {
    e.preventDefault()
    this.setState({
      url: this.state.exampleUrl,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { url } = this.state
    if (_isEmpty(url)) {
      return
    }
    const parsedUrl = new URL(url)
    if (parsedUrl.host.indexOf(config.validator.documentUrl) < 0) {
      console.error('Invalid url', url, config.validator.documentUrl)
      return this.setState({
        errorMessage: `Invalid URL, it must be an ${config.service.name} document URL`,
      })
    }
    const query = config.getPathname(url)
    return this.props.history.push(`${query}#root`)
  }

  onHistoryClick = (e, url) => {
    this.setState(
      {
        url,
        isHistoryLoading: true,
      },
      () => {
        this.handleSubmit(e)
      }
    )
  }

  getHistory = () => {
    return api.getHistory()
  }

  LatestDocuments = (props) => {
    const { recentDownloads, onClick, isLoading } = props
    const handleClick = (url) => {
      return (e) => {
        e.persist()
        e.preventDefault()
        onClick(e, url)
      }
    }
    return recentDownloads.map((item, index) => {
      const { url } = item
      const pathname = config.getPathname(url)
      const documentUrl = urlJoin('', pathname)
      return (
        <div key={index} className="col">
          <Card className="card" style={{ width: '18rem' }}>
            <img className="card-img-top" src={_first(item.pages)} alt={item.name} />
            <div className="card-body">
              <h5 className="card-title">{item.name}</h5>
              <p>{_size(item.pages)} pages</p>
              <a className="btn btn-primary stretched-link" href={documentUrl} role="button">
                View & download
              </a>
            </div>
          </Card>
        </div>
      )
    })
  }

  History = (props) => {
    const { recentDownloads, onClick, isLoading } = props
    const { LatestDocuments } = this
    return (
      <HistorySection id="history">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Recent downloaded documents</h2>
            </div>
          </div>
          <div className="row">
            <LatestDocuments recentDownloads={recentDownloads} onClick={onClick} isLoading={isLoading} />
          </div>
          {isLoading && (
            <div className="row">
              <div className="col">
                <LoadingSpinner />
              </div>
            </div>
          )}
        </div>
      </HistorySection>
    )
  }

  componentDidMount() {
    console.log('App version:', config.version)
    this.getHistory()
      .then((recentDownloads) => {
        this.setState({
          isHistoryLoading: false,
          recentDownloads,
        })
      })
      .catch((e) => {
        console.error('Error fetching history', e)
      })
  }

  componentDidUpdate() {
    window.activateAds()
  }

  render() {
    const { isLoading, isHistoryLoading, url, errorMessage, recentDownloads, exampleUrl } = this.state
    const { ErrorMessage, handleExampleClick, History, onHistoryClick } = this
    const placeholder = `Paste a ${config.service.name} Document URL here...`
    return (
      <div>
        <section id="download" className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="DocumentUrl">
                      Copy and paste the url of the {config.service.name} document you need to download as PDF into the
                      textbox and click on "View pages and download PDF"
                    </label>
                    <input
                      type="url"
                      value={url}
                      onChange={this.handleChange}
                      className="form-control"
                      id="DocumentUrl"
                      aria-describedby="urlHelp"
                      placeholder={placeholder}
                    />
                    <small id="urlHelp" className="form-text text-muted">
                      You can try this {config.service.name} publication example:{' '}
                      <SampleLink href="" onClick={handleExampleClick}>
                        {exampleUrl}
                      </SampleLink>
                    </small>
                  </div>
                  <ErrorMessage message={errorMessage} />
                  <LoadingButton type="submit" label="View pages and download PDF" isLoading={isLoading} />
                </form>
              </div>
            </div>
          </div>
        </section>
        <History recentDownloads={recentDownloads} onClick={onHistoryClick} isLoading={isHistoryLoading} />
      </div>
    )
  }
}

const SampleLink = styled.a`
  overflow-wrap: break-word;
`

const Card = styled.div`
  margin-bottom: 20px;
`

const HistorySection = styled.section`
  padding-top: 50px;
`

export default Home
