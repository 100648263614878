const moment = require('moment')

const url = 'https://flipsnackdownload.com/'
const shortTitle = 'FlipSnackDownload'
const description =
  "FlipSnackDownload.com is a free online tool for converting any document from FlipSnack to an optimized PDF. It's free and easy to use."

const updatedAt = moment().utc().format()

const exampleUrls = ['https://www.flipsnack.com/Decouvertes/f19011152-fzt8oa03x.html']

let apiRootUrl = 'https://flipsnackdownload.herokuapp.com'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: false,
  documentRoot: '/:author/:document',
  analyticsDocumentRoot: '/:author/:document',
  apiRootUrl,
  adsense: {
    enabled: false,
  },
  cookieConsent: {
    enabled: false,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { author, document } = params
    return `https://www.flipsnack.com/${author}/${document}`
  },
  service: {
    name: 'FlipSnack',
    url: 'https://www.flipsnack.com',
  },
  url,
  updatedAt,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    googleSiteValidation: 'lgARgDNacu1PqorxFm2R8EKoUJbgqhfSPBySMncL9UU',
    google: '141751933-1',
    viewId: '196590212',
    yandex: '17e8f2be7405a52f',
  },
  contactEmail: 'contact@flipsnackdownload.com',
  meta: {
    description,
    keywords:
      'flipsnack, pdf,download,free,flipsnack to pdf,flipsnack download,flipsnack pdf download,pdf download,flipsnack downloader',
    author: 'Ludovic LEFEVRE',
    title: 'FlipSnack To PDF Download Tool',
    applicationName: 'FlipSnackDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'FlipSnack Download',
    shortTitle: 'FlipSnack Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fflipsnackdownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fflipsnackdownload.com&text=FlipSnackDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//flipsnackdownload.com&title=FlipSnack%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'flipsnack',
  },
  screenshots: ['/img/flipsnack/get-flipsnack-document-url-01.jpg'],
}

export default config
