import React, { Component, useState } from 'react'
import styled from 'styled-components'
import _isEmpty from 'lodash/isEmpty'
import _has from 'lodash/has'
import _size from 'lodash/size'
import _truncate from 'lodash/truncate'
import api from '../utils/api/api'
import Gallery from '../components/Gallery'
import LoadingButton from '../components/LoadingButton.js'
import LoadingSpinner from '../components/LoadingSpinner'
import config from '../config'
import log from '../utils/log'

class Document extends Component {
  url = config.getDocumentUrlFromParams(this.props.match.params, this.props.location.search)
  getImages = config.getImages || api.getImages

  state = {
    pictures: {},
    downloadLink: undefined,
    isLoading: true,
    errorMessage: '',
    pdfErrorMessage: '',
  }

  ErrorMessage = (props) => {
    const { message } = props
    if (_isEmpty(message)) {
      return null
    }
    return (
      <div className="alert alert-warning" role="alert">
        {message}
      </div>
    )
  }

  handleProgression = (progression) => {
    if (progression > 100) {
      progression = 100
    }
    this.setState({ progression })
  }

  handlePdfDownload = (e) => {
    this.setState({
      isLoading: true,
      pdfErrorMessage: '',
      downloadLink: '',
    })
    const { pictures } = this.state
    if (!_has(pictures, 'pages')) return

    api
      .generatePdf(this.url)
      .then((response) => {
        const { id, outputFile } = response
        if (outputFile) {
          return response
        }
        return api.checkStatus(id, this.handleProgression)
      })
      .then((response) => {
        this.setState({
          downloadLink: response.outputFile,
          isLoading: false,
        })
      })
      .catch((err) => {
        console.error('PDF generation', err)
        const pdfErrorMessage = 'Error during PDF generation'
        this.setState({
          pdfErrorMessage,
          isLoading: false,
        })
      })
  }

  ImagesToPdfButton = (props) => {
    const { pictures, downloadLink, isLoading, onClick } = props
    if (!_isEmpty(downloadLink)) {
      return (
        <a
          href={downloadLink}
          className="btn btn-outline-success btn-lg btn-block"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="d-none d-sm-block">
            <i className="fas fa-thumbs-up" />
            &nbsp;Your PDF is ready, click here to download it&nbsp;
            <i className="far fa-file-pdf" />
          </span>
          <span className="d-block d-sm-none">
            <i className="fas fa-thumbs-up" />
            &nbsp;Download your PDF
          </span>
        </a>
      )
    }
    if (!_has(pictures, 'pages')) return null
    const pageLimit = 1000
    if (_size(pictures.pages) > pageLimit) {
      return (
        <div className="alert alert-warning" role="alert">
          Sorry, for the moment you can't download document with more than {pageLimit} pages.
        </div>
      )
    }
    let loadingLabel = `Processing...`
    const { progression, showDownloadButton } = this.state
    //if (!showDownloadButton && process.env.NODE_ENV!=='development') return null
    if (progression > 0) {
      loadingLabel = `Processing... ${progression}%`
    }
    return (
      <LoadingButton
        id="btPdfDownload"
        label="Save all pages to PDF"
        loadingLabel={loadingLabel}
        className="btn-lg btn-block"
        isLoading={isLoading}
        onClick={onClick}
      />
    )
  }

  componentDidMount() {
    window.adsEnabled = () => {
      this.setState({ showDownloadButton: true })
    }
    window.adsDisabled = () => {
      this.setState({ showDownloadButton: false })
    }
    this.getImages(this.url)
      .then((pictures) => {
        const newState = {
          pictures,
        }
        if (_has(pictures, 'pdfUrl')) {
          newState.downloadLink = `https://assets.img2pdf.net/download${pictures.pdfUrl}`
        }
        if (_has(pictures, 'errorMessage')) {
          newState.pdfErrorMessage = pictures.errorMessage
        }
        this.setState(newState)
      })
      .catch((e) => {
        console.error('handleSubmit', e)
        this.setState({
          errorMessage: 'Error during document page extraction.',
        })
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        })
        const pageAnchor = document.getElementById('pages')
        if (!pageAnchor) {
          return
        }
        pageAnchor.scrollIntoView()
        window.location.hash = 'pages'
      })
  }

  componentDidUpdate() {
    window.activateAds()
  }

  render() {
    const { isLoading, url, pictures, downloadLink, errorMessage, pdfErrorMessage } = this.state
    const { ErrorMessage, handlePdfDownload, ImagesToPdfButton } = this
    return (
      <div>
        <PageSection id="pages" className="bg-light">
          <a name="pages">
            <div className="container">
              <div className="row">
                <AdContainer className="col">
                  <ins
                    className="adsbygoogle activate"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-1611108288002181"
                    data-ad-slot="7343710550"
                    data-ad-format="auto"
                  />
                </AdContainer>
              </div>
            </div>
          </a>
          {!_isEmpty(pictures) && (
            <div>
              <div className="container">
                <div className="row">
                  <CenteredContent className="col">
                    <ImagesToPdfButton
                      url={url}
                      pictures={pictures}
                      downloadLink={downloadLink}
                      isLoading={isLoading}
                      onClick={handlePdfDownload}
                    />
                  </CenteredContent>
                </div>
                <div className="row">
                  <div className="col">
                    <ErrorMessage message={pdfErrorMessage} />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <DocumentTitle>{pictures.name}</DocumentTitle>
                  </div>
                </div>
                {!_isEmpty(pictures.url) && (
                  <div className="row">
                    <div className="col text-center">
                      Original {config.service.name} document:{' '}
                      <a
                        href={pictures.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ overflowWrap: 'break-word' }}
                      >
                        {_truncate(pictures.url, { length: 100 })}
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <Gallery pictures={pictures} />
              <div className="container">
                <div className="row">
                  <AdContainer className="col">
                    <ins
                      className="adsbygoogle activate"
                      style={{ display: 'block' }}
                      data-ad-client="ca-pub-1611108288002181"
                      data-ad-slot="7343710550"
                      data-ad-format="auto"
                    />
                  </AdContainer>
                </div>
              </div>
            </div>
          )}
          {isLoading && <LoadingSpinner />}
        </PageSection>
      </div>
    )
  }
}

const AdContainer = styled.div`
  margin-bottom: 20px;
`

const CenteredContent = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
`

const DocumentTitle = styled.h1`
  text-align: center;
  text-decoration: none;
  color: black;
`

const PageSection = styled.section`
  padding-top: 50px !important;
  padding-bottom: 50px !important;
`

export default Document
