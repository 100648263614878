import moment from 'moment'

const url = 'https://joomagdownload.com/'
const shortTitle = 'JoomagDownload'
const description =
  "JoomagDownload.com is a free online tool for converting any document from Joomag to an optimized PDF format online. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = ['https://view.joomag.com/trend-industrial-tooling-catalogue-2016/0267465001465558202']

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: false,
  documentRoot: '/:title/:document',
  analyticsDocumentRoot: '/:title/:document',
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/6rPeL/stub" referrerpolicy="origin" charset="utf-8"></script>
      <script>
      window.Sddan = {
          "info": {
              "hd_m" : "",
              "hd_s256" : "",
              "uf_postal_code" : "",
              "uf_bday" : "",
              "uf_gender" : "",
          }
      };
      </script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/6rPeL/cmp" referrerpolicy="origin" charset="utf-8" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { title, document } = params
    return `https://view.joomag.com/${title}/${document}`
  },
  service: {
    name: 'Joomag',
    url: 'https://www.joomag.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    googleSiteValidation: '',
    google: 'UA-142225184-1',
    viewId: '196943308',
    yandex: '1f070d1c0f8c0c44',
    gtm: 'GTM-MVRRN84',
  },
  contactEmail: 'contact@joomagdownload.com',
  meta: {
    description,
    keywords:
      'joomag, pdf,download,free,joomag to pdf,joomag download,joomag pdf download,pdf download,joomag downloader',
    author: 'Ludovic LEFEVRE',
    title: 'Joomag To PDF Download Tool',
    applicationName: 'JoomagDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'Joomag Download',
    shortTitle: 'Joomag Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fjoomagdownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fjoomagdownload.com&text=JoomagDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//joomagdownload.com&title=Joomag%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'joomag',
  },
  screenshots: ['/img/joomag/get-joomag-document-url-01.jpg'],
}

export default config
