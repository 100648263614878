import 'react-app-polyfill/ie11'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import _isArray from 'lodash/isArray'
import './index.css'
import Home from './pages/Home'
import Document from './pages/Document'
import Error from './pages/Error'
import PrivacyPolicy from './pages/PrivacyPolicy'
import { unregister } from './utils/registerServiceWorker'
import ScrollToTop from './components/ScrollToTop'
import config from './config'

const routes = _isArray(config.documentRoot) ? config.documentRoot : [config.documentRoot]

render(
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route key={0} path="/" exact={true} component={Home} />
        <Route key={1} path="/privacy-policy" exact={true} component={PrivacyPolicy} />
        {routes.map((route, index) => {
          return <Route key={index + 2} path={route} exact={true} component={Document} />
        })}
        <Route key={routes.length + 2} component={Error} />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
)

unregister()
//registerServiceWorker()
